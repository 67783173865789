<!-- @format -->

<template>
  <div class="flex justify-end">
    <div
      class="bg-teal p-3 rounded-md text-white text-sm cursor-pointer"
      @click="navigateRoute(0)"
    >
      New Compliment
    </div>
  </div>
  <div class="bg-white rounded-md border border-gray-200">
    <div class="flex justify-between my-5">
      <div class="ml-5 text-xl font-medium mt-2">Compliments List</div>
      <div class="flex gap-5 mr-5">
        <div>
          <v-select
            v-if="!isManager"
            class="w-72"
            label="practice_name"
            placeholder="Select Practice"
            :options="practices"
            @update:modelValue="selectedPractice"
          ></v-select>
        </div>
        <div>
          <DatePicker
            :selectedDate="complimentFilterDate"
            @selected-date="complimentDateFilter"
            @cleared-date="complimentDateFilter"
            placeholder="Date"
            class="w-72 border border-gray-200 rounded"
          />
        </div>
      </div>
    </div>
    <TableSkeletonLoader v-if="loader" :loader="loader" :cols="6" :rows="4" />
    <ComplimentListTable
      v-else
      :tableHeading="tableHeader"
      :tableBody="isManager ? complimentsMa?.data : compliments?.data"
      :isManager="isManager"
    />
    <Pagination
      :currentPage="
        isManager ? complimentsMa?.current_page : compliments?.current_page
      "
      :totalPages="
        isManager ? complimentsMa?.last_page : compliments?.last_page
      "
      @page-changed="atPageChange"
    />
  </div>
</template>
<script setup>
import { ref, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import DatePicker from "@/components/newUi/atoms/DatePicker.vue";
import ComplimentListTable from "@/components/newUi/tables/ComplimentListTable.vue";

import TableSkeletonLoader from "@/components/newUi/atoms/TableSkeletonLoader.vue";
import { useStore } from "vuex";
import Pagination from "@/components/newUi/atoms/Pagination.vue";
const complimentFilterDate = ref(null);
const store = useStore();
const router = useRouter();
const page = ref(1);
const practices = ref("");
const selectedPracticeId = ref();
const route = useRoute();
const isManager = ref(parseInt(route.params.isManager));
const tableHeader = ref([
  "Patient NHS No",
  "Date",
  "Source of Compliments",
  "Recipient Compliment",
  "Practice",
  "Details",
]);
const loader = ref(false);

const selectedPractice = (practice) => {
  if (practice) {
    selectedPracticeId.value = practice?.id;
  } else {
    selectedPracticeId.value = null;
  }
  filterAllCompliments(1);
};
const complimentDateFilter = (selectedDate) => {
  if (selectedDate) {
    complimentFilterDate.value = selectedDate;
  } else {
    complimentFilterDate.value = null;
  }
  filterAllCompliments(1);
};

const navigateRoute = (isView) => {
  if (isManager.value) {
    router.push({
      name: "ma-monitoringOfPracticeFunctionCompliment-newCompliment",
      params: { view: isView, isManager: 1, complimentId: "0" },
    });
  } else {
    router.push({
      name: "hq-monitoringOfPracticeFunctionCompliment-newCompliment",
      params: { view: isView, isManager: 0, complimentId: "0" },
    });
  }
};

const compliments = computed(() => {
  return store.getters["hqCompliments/getAllCompliments"];
});
const complimentsMa = computed(() => {
  return store.getters["maCompliments/getAllCompliments"];
});

const filterAllCompliments = (page) => {
  if (isManager.value) {
    if (complimentFilterDate.value) {
      store.dispatch("maCompliments/fetchAllComplimentsMa", {
        practice: localStorage.getItem("practice"),
        receiving_date: complimentFilterDate.value,
        page: page,
      });
    } else {
      fetchAllCompliments(page);
    }
  } else {
    if (selectedPracticeId.value && complimentFilterDate.value) {
      store.dispatch("hqCompliments/fetchAllCompliments", {
        practice: selectedPracticeId.value,
        receiving_date: complimentFilterDate.value,
        page: page,
      });
    } else if (selectedPracticeId.value && !complimentFilterDate.value) {
      store.dispatch("hqCompliments/fetchAllCompliments", {
        practice: selectedPracticeId.value,
        page: page,
      });
    } else if (!selectedPracticeId.value && complimentFilterDate.value) {
      store.dispatch("hqCompliments/fetchAllCompliments", {
        receiving_date: complimentFilterDate.value,
        page: page,
      });
    } else {
      fetchAllCompliments();
    }
  }
};

const atPageChange = (pageNumber) => {
  page.value = pageNumber;
  if (selectedPracticeId.value || complimentFilterDate.value) {
    filterAllCompliments(pageNumber);
  } else {
    fetchAllCompliments(pageNumber);
  }
};

const fetchAllCompliments = async (pageNumber) => {
  loader.value = true;
  if (isManager.value) {
    await store.dispatch("maCompliments/fetchAllComplimentsMa", {
      practice: localStorage.getItem("practice"),
      page: pageNumber,
    });
  } else {
    await store.dispatch("hqCompliments/fetchAllCompliments", {
      page: pageNumber,
    });
  }
  loader.value = false;
};

onMounted(async () => {
  fetchAllCompliments(1);
  if (!isManager.value)
    await store.dispatch("hqCompliments/loadPractices").then((response) => {
      practices.value = response;
    });
});
</script>
<style scoped>
::v-deep .vs__dropdown-toggle {
  padding: 8px;
  background-color: white;
  cursor: pointer !important;
}
::v-deep ul li:not(:hover) {
  background-color: white !important;
  color: black;
}
::v-deep ul li:hover {
  background-color: teal !important;
  color: white;
}

::v-deep .vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 40px);
}

::v-deep .vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 95%;
  overflow: hidden;
}
::v-deep ul li {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

::v-deep .vs__search {
  --vs-search-input-placeholder-color: rgb(148, 148, 148);
}
</style>
